import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Swal from "sweetalert2";
import axios from "axios";
import { attend } from "../config/filter";
import { BaseUrl } from "../middleware/config";
import { Table, Button, Modal, Header } from "semantic-ui-react";
import { Link } from "react-router-dom";
import Clock from "../components/clock";
import "./Register.scss";
import { Icons } from "../config/constants";
import { employeeData, employeeList } from "../config/filter";
// import { format } from 'date-fns-tz';

const Register = (props) => {
  const [role, setRoles] = useState([]);
  const [open, setOpen] = useState(false);
  const [isSignIn, setIsSignIn] = useState([]);
  const dialogRef = useRef(null)
  const navigate = useNavigate();

  const handleOpenDialog = (val) => {
    setIsSignIn(val)
    dialogRef.current.showModal()
  }

  const closeDialog = () => {
    dialogRef.current.close()
    setEmpIdValue("")
  }

  const [attendance, setAttendance] = useState({});
  // const [homeIp, setHomeIp] = useState({});
  // const [homeIpLocal, setHomeIpLocal] = useState({});
  const currentDate = new Date().toJSON().slice(0, 10);
  const [startDate, setStartDate] = useState(currentDate);


  const [saveData, setSaveData] = useState({
    id: "",
    attendance: "",
    password: "",
    time: new Date().toLocaleTimeString()
  });

  // const [barCodeData, setBarCodeData] = useState({
  //   empId: "",
  //   time: new Date().toLocaleTimeString()
  // });

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const [empIdValue, setEmpIdValue] = useState("")
  // const fetchHomeIp = async () => {
  //   fetch('https://ipinfo.io/json')
  //     .then(response => response.json())
  //     .then(data => {
  //       console.log(data.ip)
  //       setHomeIp(data.ip);
  //     })
  //     .catch(error => {
  //       console.error('Error fetching IP:', error);
  //     });
  // }

  // const homeIpLocal = async 

  // function compareIPAddresses(ip1, ip2) {
  //   const ip1Parts = ip1.split('.');
  //   const ip2Parts = ip2.split('.');

  //   // Ensure both IP addresses have 4 octets
  //   if (ip1Parts.length !== 4 || ip2Parts.length !== 4) {
  //     return false;
  //   }

  //   // Compare each octet
  //   for (let i = 0; i < 4; i++) {
  //     const octet1 = parseInt(ip1Parts[i], 10);
  //     const octet2 = parseInt(ip2Parts[i], 10);

  //     if (octet1 !== octet2) {
  //       return false;
  //     }
  //   }

  //   return true;
  // }

  const logout = () => {
    localStorage.clear('isLogin');
    let x = JSON.parse(localStorage.getItem('isLogin'));
    if (x === undefined || x === null || x !== 'OK') {
      navigate('/login', { replace: true });
    }
  }

  const getAttendance = async () => {
    await axios
      .get(`${BaseUrl}/register/byDate?date=${startDate}`)
      .then((getAttendance) => {
        setAttendance(getAttendance.data);
      });
  };

  useEffect(() => {
    let x = JSON.parse(localStorage.getItem('isLogin'));
    console.log('x', x)
    if (x !== 'OK') {
      navigate('/login', { replace: true });
    }
    // getAttendance();
    getEmployeeList();

    //console.log('setEmpIdValue', empIdValue)
  }, [navigate, empIdValue])
  
  useEffect(() => {
    // eslint-disable-next-line
    getAttendance();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps



  const getEmployeeList = async () => {
    var result = await employeeData();
    setRoles(result);
  };

  // const getIp = async () => {
  //   let response = await axios.get(`${BaseUrl}/register/homeIp`)
  //   setHomeIpLocal(response.data.body[0].homeIp)
  // }
  const handleFormSubmit = async () => {

    //.log(homeIp, homeIpLocal)

    // let ipResult = compareIPAddresses(homeIp, homeIpLocal);
    // if (ipResult === false) {
    //   alert('You are in not present in home network');
    //   return;
    // }

    let response = await axios.post(`${BaseUrl}/register/create`, saveData);
    if (response.data.status === 200) {
      swal({
        text: `${response.data.message}`,
        icon: "success",
        button: "OK",
      });

    } else {
      Swal.fire({
        icon: "error",

        text: `${response.data.message}`,
      });
    }
    setTimeout(function () {
      window.location.reload(true);
    }, 900);
  };

  useEffect(() => {
    console.log('setEmpIdValue', empIdValue)
  }, [empIdValue])

  const handleFormSubmitBarcode = async () => {

    // console.log('isSignIn', isSignIn)
    if (empIdValue.length !== 10) return;
    // if (!isSignIn) return;

    let barCodeData = {
      empId: empIdValue,
      time: new Date().toLocaleTimeString()
    }

    console.log('barCodeData', barCodeData)

    let response = isSignIn === false ? await axios.put(`${BaseUrl}/register/signOutByBarcode`, barCodeData) : await axios.post(`${BaseUrl}/register/createByBarcode`, barCodeData);
    closeDialog();
    if (response.data.status === 200) {
      swal({
        text: `${response.data.message}`,
        icon: "success",
        button: "OK",
      });
      navigate("/");
    } else {
      Swal.fire({
        icon: "error",

        text: `${response.data.message}`,
      });
    }
    setTimeout(function () {
      window.location.reload(true);
    }, 900);
  };

  // const [empIdValue, setEmpIdValue] = useState('');

  const [breakUpdate, setBreakData] = useState({
    id: "",
    password: "",
    employeeUniqueId: "",
    time: new Date().toLocaleTimeString()
  });

  const [signOut, setSignOut] = useState({
    id: "",
    password: "",
    employeeUniqueId: "",
    pass: 0,
    time: new Date().toLocaleTimeString()
  });
  let nav = useNavigate();
  const Go = async () => {
    let result = await axios.put(`${BaseUrl}/register/signOut`, signOut);
    nav("/");
    if (result.data.status === 200) {
      swal({
        title: `${result.data.message}`,
        text: "",
        icon: "success",
        button: "OK",
      });
    } else {
      Swal.fire({
        icon: "error",

        text: `${result.data.message}`,
      });
    }
    window.location.reload(true);
  };
  let navigat = useNavigate();
  const handleSubmit = async () => {
    let response = await axios.put(
      `${BaseUrl}/register/breakApplied`,
      breakUpdate
    );

    navigat("/");
    if (response.data.status === 200) {
      swal({
        title: `${response.data.message}`,
        text: "",
        icon: "success",
        button: "OK",
      });
    } else {
      Swal.fire({
        icon: "error",

        text: `${response.data.message}`,
      });
    }
    window.location.reload(true);
  };

  return (
    <div className="Register">


      <div className="head">
        <h2 className="headding">Select details to Login bellow</h2>
        <Clock />
      </div>

      <div>
        <div className="main_box">
          <button
            className="btn"
            type="submit"
            style={{
              background: "rgb(33 186 69)",
              color: "white",
              fontSize: "Large",
              fontFamily: "sans-serif",
            }}
            onClick={() => handleOpenDialog(true)}
          >
            Scan Signin
          </button>
        </div>

        <div className="main_box">
          <button
            className="btn"
            type="submit"
            style={{
              background: "rgb(33 186 69)",
              color: "white",
              fontSize: "Large",
              fontFamily: "sans-serif",
            }}
            onClick={() => handleOpenDialog(false)}
          >
            Scan Signout
          </button>
        </div>
      </div>


      <div className="main_box">
        <div className="s1">
          <label htmlFor="p">
            Select Employee Name:-
            <select
              id="p"
              type="text"
              required
              onChange={(e) => setSaveData({ ...saveData, id: e.target.value })}
            >
              <option value="">List of Employees</option>
              {employeeList(role)}
            </select>
          </label>
        </div>
        <div className="s2">
          <label htmlFor="a">
            Select Attendance:-
            <select
              id="a"
              type="text"
              required
              onChange={(e) =>
                setSaveData({ ...saveData, attendance: e.target.value })
              }
            >
              <option value="">Attendance</option>
              {attend}
            </select>
          </label>
        </div>
        <div className="s3">
          <label htmlFor="password" className="enter_pass">
            Enter Password:-{" "}
          </label>
          <input
            style={{ width: "180px", padding: "5px" }}
            type={passwordShown ? "text" : "password"}
            id="password"
            placeholder="password"
            required
            onChange={(e) =>
              setSaveData({ ...saveData, password: e.target.value })
            }
          />
          <Icons.Show className="Icon_reg" onClick={togglePassword} />
        </div>
        <button
          className="btn"
          type="submit"
          style={{
            background: "rgb(33 186 69)",
            color: "white",
            fontSize: "Large",
            fontFamily: "sans-serif",
          }}
          onClick={handleFormSubmit}
        >
          Login
        </button>
        <button
          className="btn"
          type="submit"
          style={{
            background: "rgb(255, 0, 0)", // Change to red
            color: "white",
            fontSize: "Large",
            fontFamily: "sans-serif",
          }}
          onClick={logout}
        >
          logout
        </button>
      </div>
      <hr className="hr" />
      <div className="datee">
        <label> Date:- </label>
        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <button className="att-btn btn" type="button" onClick={getAttendance}>
          Get Attendance
        </button>

      </div>
      <div>
        <Table celled className="table">
          <Table.Header className="tbl_head">
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>Full Name</Table.HeaderCell>
              <Table.HeaderCell>EmployeeId</Table.HeaderCell>
              <Table.HeaderCell>Attendance Date</Table.HeaderCell>
              <Table.HeaderCell>Login Time</Table.HeaderCell>
              <Table.HeaderCell>Break Taken</Table.HeaderCell>
              <Table.HeaderCell>Break Minutes</Table.HeaderCell>
              <Table.HeaderCell>Logout Time</Table.HeaderCell>
              <Table.HeaderCell>Working Hours</Table.HeaderCell>
              <Table.HeaderCell>Office Hours</Table.HeaderCell>
              <Table.HeaderCell>Attendance</Table.HeaderCell>
              <Table.HeaderCell>Break</Table.HeaderCell>
              <Table.HeaderCell>Logout</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {attendance.body &&
              attendance.body.map((data, index) => {
                return (
                  <Table.Row key={data.id}>
                    <Table.Cell>{index + 1}</Table.Cell>
                    <Table.Cell>{data.fullName}</Table.Cell>
                    <Table.Cell>{data.employeeId}</Table.Cell>
                    <Table.Cell>{data.date}</Table.Cell>
                    {/* <Table.Cell>{format(new Date(`1970-01-01T${data.inTime}Z`), 'hh:mm:ss A', { timeZone: 'Asia/Kathmandu' })}</Table.Cell> */}
                    <Table.Cell>{data.inTime}</Table.Cell>
                    {/* <Table.Cell>{moment.tz(data.inTime, 'HH:mm:ss', 'UTC').tz('Asia/Kathmandu').format('hh:mm:ss A')}</Table.Cell> */}
                    {/* <Table.Cell>{(data.inTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' })}</Table.Cell> */}
                    <Table.Cell>{data.breakTaken}</Table.Cell>
                    <Table.Cell>{data.breakTakenMinutes}</Table.Cell>
                    <Table.Cell> {data.outTime}</Table.Cell>
                    <Table.Cell>{data.totalWorkingHours}</Table.Cell>
                    <Table.Cell>{data.attendence === 'PRESENT' ? data.totalOfficeHours : 'NOT IN OFFICE'}</Table.Cell>

                    <Table.Cell
                      style={
                        data.attendence === "PRESENT"
                          ? { color: "green" }
                          : { color: "red" }
                      }
                    >
                      {data.attendence}
                    </Table.Cell>
                    <Table.Cell>
                      {data.attendence === 'PRESENT' ? <Modal
                        closeIcon
                        open={open}
                        trigger={
                          <Link to="/">
                            <Button
                              className="btn"
                              color="green"
                              onClick={() =>
                                setBreakData({
                                  id: data.id,
                                  password: "",
                                  employeeUniqueId: data.employeeUniqueId,
                                  time: new Date().toLocaleTimeString()
                                })
                              }
                            >
                              {data.breakTaken === "NO" || null
                                ? "Apply"
                                : "Remove"}
                            </Button>
                          </Link>
                        }
                        onClose={() => setOpen(false)}
                        onOpen={() => setOpen(true)}
                      >
                        <Header icon="archive" content="Enter your password" />
                        <Modal.Content>
                          <div className="pass">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <label htmlFor="password">Password*:</label>
                              <input
                                type="password"
                                value={
                                  signOut.pass === 0
                                    ? breakUpdate.password
                                    : signOut.password
                                }
                                placeholder="******"
                                onChange={(e) =>
                                  signOut.pass === 0
                                    ? setBreakData({
                                      ...breakUpdate,
                                      password: e.target.value,
                                    })
                                    : setSignOut({
                                      ...signOut,
                                      password: e.target.value,
                                    })
                                }
                                id="name"
                                required
                              ></input>
                              <button
                                className="mdl-btn"
                                type="submit"
                                onClick={signOut.pass === 0 ? handleSubmit : Go}
                              >
                                Ok
                              </button>
                            </div>
                          </div>
                        </Modal.Content>
                      </Modal> : <></>}

                    </Table.Cell>
                    <Table.Cell>
                      {
                        data.attendence === 'PRESENT' ?
                          <Modal
                            closeIcon
                            open={open}
                            trigger={
                              <Link to="/">
                                <Button
                                  color="red"
                                  onClick={() =>
                                    setSignOut({
                                      id: data.id,
                                      password: "",
                                      employeeUniqueId: data.employeeUniqueId,
                                      time: new Date().toLocaleTimeString()
                                    })
                                  }
                                >
                                  Logout
                                </Button>
                              </Link>
                            }
                            onClose={() => setOpen(false)}
                            onOpen={() => setOpen(true)}
                          >
                            <Header icon="" content="Enter your password" />
                            <Modal.Content>
                              <div className="pass">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <label className="model_pass" htmlFor="password">
                                    Password*
                                  </label>
                                  <input
                                    type="password"
                                    value={
                                      signOut.pass === 0
                                        ? breakUpdate.password
                                        : signOut.password
                                    }
                                    placeholder="******"
                                    onChange={(e) =>
                                      signOut.pass === 0
                                        ? setBreakData({
                                          ...breakUpdate,
                                          password: e.target.value,
                                        })
                                        : setSignOut({
                                          ...signOut,
                                          password: e.target.value,
                                        })
                                    }
                                    id="name"
                                    required
                                  ></input>
                                  <button
                                    className="btn"
                                    type="submit"
                                    onClick={signOut.pass === 0 ? handleSubmit : Go}
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </Modal.Content>
                          </Modal> : <></>
                      }

                    </Table.Cell>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
      </div>

      <dialog ref={dialogRef} style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%,-50%)", border: "none", padding: "50px" }}>
        <>
          <label>EmpID</label>
          {/* <input type="text" value={empIdValue} readOnly autoFocus/> */}
          {/* <input type="text" onChange={(e) => { setEmpIdValue(e.target.value) }} value={empIdValue} autoFocus readOnly /> */}
          <input
            type="text"
            value={empIdValue}
            onChange={(e) => setEmpIdValue(e.target.value)}
            autoFocus
            onKeyPress={handleFormSubmitBarcode}
          />
          <button onClick={() => closeDialog()}>&times;</button>
        </>

      </dialog>
    </div>
  );
};
export default Register;
