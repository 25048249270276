import React from "react";
import "./footer.scss";

const Footer = () => {
  return (
    <div>
      <div className="footer">
        Copyright ©{new Date().getFullYear()} — Lennobyte Solutions Pvt Ltd.
      </div>
    </div>
  );
};

export default Footer;
