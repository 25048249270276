import React, { useEffect, useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import { BaseUrl } from '../middleware/config';
import { employeeList, employeeData } from '../config/filter';
import { Icons } from '../config/constants';

const UpdatePass = () => {
    useEffect(() => {
        getEmployeeList();
    }, []);

    const [role, setRoles] = useState([]);
    const [saveData, setSaveData] = useState({
        id: '',
        hrPassword: '',
        password: '',
        cnfPassword: ''
    });
    const [passwordShown, setPasswordShown] = useState(false);

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    const getEmployeeList = async () => {
        const result = await employeeData();
        setRoles(result);
    };

    const resetPassword = async () => {
        if (saveData.password === saveData.cnfPassword) {
            try {
                const result = await axios.put(`${BaseUrl}/employee/updatepass`, saveData);
                console.log(result);
                if (result.data.status === 200) {
                    swal({
                        title: result.data.message,
                        text: "",
                        icon: "success",
                        button: "OK",
                    });
                    window.location.reload(true);
                } else {
                    Swal.fire({
                        icon: 'error',
                        text: result.data.message,
                    });
                }
            } catch (error) {
                console.error('Error updating password:', error);
            }
        } else {
            alert('Passwords does not match.');
            return;
        }
        // window.location.reload(true);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
            <h2 style={{ marginBottom: '20px' }}>Update Your Password By Hr</h2>

            <div className='form-group' style={{ marginBottom: '20px', width: '300px' }}>
                <label htmlFor='p_2'>Select Employee:</label>
                <select
                    id='p_2'
                    type='text'
                    required
                    onChange={(e) => setSaveData({ ...saveData, id: e.target.value })}
                >
                    <option value="">List of Employees</option>
                    {employeeList(role)}
                </select>
            </div>

            <div className='form-group' style={{ marginBottom: '20px', width: '300px' }}>
                <label className="user_label_2" htmlFor='Hr_password'>Enter Hr Password:</label>
                <input
                    type={passwordShown ? 'text' : 'password'}
                    id="password"
                    placeholder='Enter Hr password'
                    required
                    onChange={(e) => setSaveData({ ...saveData, hrPassword: e.target.value })}
                />
                <Icons.Show className='update_pass' onClick={togglePassword} />
            </div>

            <div className='form-group' style={{ marginBottom: '20px', width: '300px' }}>
                <label className='pass_2' htmlFor='password'>Enter User Password:</label>
                <input
                    type={passwordShown ? 'text' : 'password'}
                    id="hr_password"
                    placeholder='Enter User password'
                    required
                    onChange={(e) => setSaveData({ ...saveData, password: e.target.value })}
                />
                <Icons.Show className="update_pass" onClick={togglePassword} />
            </div>

            <div className='form-group' style={{ marginBottom: '20px', width: '300px' }}>
                <label className='pass_2' htmlFor='password'>Confirm User Password:</label>
                <input
                    type={passwordShown ? 'text' : 'password'}
                    id="hr_password"
                    placeholder='Enter User password'
                    required
                    onChange={(e) => setSaveData({ ...saveData, cnfPassword: e.target.value })}
                />
                <Icons.Show className="update_pass" onClick={togglePassword} />
            </div>

            <button
                className='btn+_2'
                type="submit"
                style={{
                    background: "rgb(33 186 69)",
                    color: 'white',
                    fontSize: 'Large',
                    fontFamily: 'sans-serif',
                    cursor: 'pointer',
                    width: '300px',
                }}
                onClick={resetPassword}
            >
                Update password
            </button>
        </div>
    );
}

export default UpdatePass;


// import React from 'react';
// import { useEffect, useState } from 'react';
// import axios from 'axios';
// import swal from 'sweetalert';
// import Swal from 'sweetalert2';
// import { BaseUrl } from '../middleware/config';
// import { employeeList, employeeData } from '../config/filter';
// import { Icons } from '../config/constants';
// // import './UpdatePass.scss'

// const UpdatePass = () => {
//     useEffect(() => {
//         getEmployeeList();
//     }, [])
//     const [role, setRoles] = useState([])
//     const getEmployeeList = async () => {
//         var result = await employeeData();
//         setRoles(result)
//     }

//     const hrPassword = process.env.HR_PASSWORD;

//     const [saveData, setSaveData] = useState({
//         id: '',
//         hrPassword: '',
//         password: '',
//         cnfPassword: ''
//     })

//     const [passwordShown, setPasswordShown] = useState(false);

//     const togglePassword = () => {
//         setPasswordShown(!passwordShown);
//     };

//     const resetPassword = async () => {
//         if(hrPassword !== saveData.hrPassword) {
//             alert('WRONG HR PASSWORD');
//             return;
//         }
//         if (saveData.password === saveData.cnfPassword) {
//             let result = await axios.put(`${BaseUrl}/employee/updatepass`, saveData)
//             console.log(result)
//             if (result.data.status === 200) {
//                 swal({
//                     title: (`${result.data.message}`),
//                     text: "",
//                     icon: "success",
//                     button: "OK",
//                 });

//             }
//             else {
//                 Swal.fire({
//                     icon: 'error',
//                     text: (`${result.data.message}`),
//                 })
//             }
//         }
//         else{
//             alert('Passwords doesnot Matched..')
//             return;
//         }
//         // window.location.reload(true);
//     }



//     return (
//         <div className="main_box_2">
//             <center className="heading_2">Update Your Password By Hr</center>

//             <div className='emp_List_2'>
//                 <label htmlFor='p_2'>Select Employee:-
//                     <select id='p_2' type='text' required
//                         onChange={(e) => setSaveData({ ...saveData, id: e.target.value })}>
//                         <option value="">List of Employees</option>
//                         {employeeList(role)}
//                     </select>
//                 </label></div>

//             <div className="hr_pass_2">
//                 <label className="user_label_2" htmlFor='Hr_password' >Enter Hr Password:-</label>
//                 <input className='label_2' type={passwordShown ? 'text' : 'password'} id="password" placeholder='Enter Hr password' required onChange={(e) => setSaveData({ ...saveData, hrPassword: e.target.value })} />
//                 <Icons.Show className='update_pass' onClick={togglePassword} />
//             </div>


//             <div className='user_Pass_2'>
//                 <label className='pass_2' htmlFor='password' >Enter user Password:-</label>
//                 <input className="label_2" type={passwordShown ? 'text' : 'password'} id="hr_password" placeholder='Enter User password' required onChange={(e) => setSaveData({ ...saveData, password: e.target.value })} />
//                 <Icons.Show className="update_pass" onClick={togglePassword} />
//             </div>

//             <div className='user_Pass_2'>
//                 <label className='pass_2' htmlFor='password' >Confirm user Password:-</label>
//                 <input className="label_2" type={passwordShown ? 'text' : 'password'} id="hr_password" placeholder='Enter User password' required onChange={(e) => setSaveData({ ...saveData, cnfPassword: e.target.value })} />
//                 <Icons.Show className="update_pass" onClick={togglePassword} />
//             </div>

//             <button className='btn+_2' type="submit" style={{ background: "rgb(33 186 69)", color: 'white', fontSize: 'Large', fontFamily: 'sans-serif' }} onClick={resetPassword}>Update password</button>
//             <div>

//             </div>
//         </div>


//     )
// }

// export default UpdatePass

