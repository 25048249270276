import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import { Icons } from '../config/constants'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Form, Button } from 'semantic-ui-react';
import './AddData.scss';
import { BaseUrl } from '../middleware/config';
import { roleData, roleList } from '../config/filter';




const AddData = () => {


  const [rolee, setRolee] = useState([])
  const [formData, setFormData] = useState({
    name: '',
    designation: '',
    phoneNumber: '',
    joiningDate: '',
    address: '',
    salary: '0.00',
    password: '',
    roleId: ''
  })

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  useEffect(() => {

    roleInfo();

  }, []);

  const roleInfo = async () => {
    var res = await roleData();
    setRolee(res)
  }

  let navigate = useNavigate();

  const handleFormSubmit = async () => {
    console.log('here')
    let response = await axios.post(`${BaseUrl}/employee/create`, formData)
    console.log('hello')

    if (response.data.status === 200) {
      swal({

        text: (`${response.data.message}`),
        icon: "success",
        button: "OK",
      });
      navigate('/GetData')


    } else {
      Swal.fire({
        icon: 'error',

        text: (`${response.data.message}`),
      })

    }
  }

  return (


    <div className='Add_Data'>


      <Form style={{ padding: "12px", margin: "12px" }}>

        <div className='add_box'>
          <label htmlFor="name">Name*:</label>
          <Icons.Name />
          <input type="text"
            value={formData.name}
            placeholder="Enter your name"
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            id="name"
            required />
        </div>

        <div className='add_box'>
          <label htmlFor="roleId">Role*:</label>
          <Icons.Name />
          <select id="roleId"
            type="text"
            required
            onChange={(e) => setFormData({ ...formData, roleId: e.target.value })}>
            <option value=''>Select Employee</option>
            {roleList(rolee)}
          </select>
        </div>

        <div className='add_box'>
          <label htmlFor="designation">Designation*:</label>
          <Icons.Designation />
          <input type="text"
            value={formData.designation}
            placeholder="Enter Your Designation"
            onChange={(e) => setFormData({ ...formData, designation: e.target.value })}
            id="designation"
            required
          ></input>
        </div>
        <div className='add_box'>
          <label htmlFor="phoneNumber">MobileNo*:</label>
          <Icons.Phone />
          <input type="number"
            value={formData.phoneNumber}
            placeholder="9xxxxxxxxx"
            onChange={(e) => setFormData({ ...formData, phoneNumber: e.target.value })}
            id="phoneNumber"
            required
          ></input>
        </div>
        <div className='add_box'>
          <label htmlFor="joiningDate">joiningDate*:</label>
          <Icons.Date />
          <input type="date"
            value={formData.joiningDate}
            placeholder="Joining date"
            onChange={(e) => setFormData({ ...formData, joiningDate: e.target.value })}
            id="joiningDate"
            required
          ></input>
        </div>
        <div className='add_box'>
          <label htmlFor="address">Address*:</label>
          <Icons.City />
          <input type="text"
            value={formData.address}
            placeholder="Enter Your Address"
            onChange={(e) => setFormData({ ...formData, address: e.target.value })}
            id="address"
            required
          ></input>
        </div>
        <div className='add_box'>
          <label htmlFor="password">password:</label>
          <Icons.Password />
          <input type={passwordShown ? "text" : "password"}
            value={formData.password}
            placeholder="*******"
            onChange={(e) => setFormData({ ...formData, password: e.target.value })}
            id="pass"
            required
          />
          <Icons.Show className="Icon_pass" onClick={togglePassword} />

        </div>
        <div className='add_box'>
          <label htmlFor="salary">Salary:</label>
          <Icons.Salary />
          <input type="text"
            value={formData.salary}
            placeholder="Enter Salary"
            onChange={(e) => setFormData({ ...formData, salary: e.target.value })}
            id="salary"
            required
          ></input>
          <Button className='add_data_btn' onClick={handleFormSubmit}>  AddData</Button>
        </div>


      </Form>
    </div>

  )
}



export default AddData

