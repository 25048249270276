import { useState, useEffect } from "react";
import { Table, Button, Form, Header, Modal } from 'semantic-ui-react';
import axios from "axios";
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import swal from 'sweetalert';
// import Swal from 'sweetalert2';
import { BaseUrl } from "../middleware/config";
import './getData.scss';




const Read = () => {
  const [apiData, setApiData] = useState({})
  const [open, setOpen] = useState(false)
  useEffect(() => {
    getData();
  }, []);


  //get Data api
  const getData = async () => {
    await axios.get(`${BaseUrl}/employee/statusdata`)
      .then((getData) => {
        setApiData(getData.data);
      })
  }

  const [update, setUpdateData] = useState({
    id: "",
    name: "",
    designation: "",
    phoneNumber: "",
    joiningDate: "",
    address: "",
    salary: "0.00",
    employeeId: "",
    roleId: ''
  })

  let navigate = useNavigate();

  // put api
  const handleFormSubmit = async () => {
    let response = await axios.put(`${BaseUrl}/employee/update`, update)


    if (response.data.status === 200) {
      navigate('/GetData')
      swal({
        text: (`${response.data.message}`),
        icon: "success",
      });
    }
    else {
      alert(`${response.data.message}`)
    }
    window.location.reload(true);
  }
  //delete api
  const deleteData = async (id, status) => {

    let actionStatus;
    status === false ? actionStatus = 0 : actionStatus = 1;

    let response = await axios.delete(`${BaseUrl}/employee/delete?id=${id}&status=${actionStatus}`)
    if (response.data.status === 200) {
      navigate('/GetData')
      swal({
        text: (`${response.data.message}`),
        icon: "success",
      });
    }
    else {
      alert(`${response.data.message}`)
    }
    setTimeout(function () {
      window.location.reload(true);
    }, 1000);
  }


  return (
    <div>
      <Button className="btn"> <Link to="/AddData"
      >Add NewEmployee</Link></Button>
      <Table celled className="tbl1">
        <Table.Header className="tbl_head">
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Designation</Table.HeaderCell>
            <Table.HeaderCell>Employee Id</Table.HeaderCell>
            <Table.HeaderCell>PhoneNumber</Table.HeaderCell>
            <Table.HeaderCell>Joining Date</Table.HeaderCell>
            <Table.HeaderCell>Address</Table.HeaderCell>
            {/* <Table.HeaderCell>Salary</Table.HeaderCell> */}
            <Table.HeaderCell>Update</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            apiData.body && apiData.body.map((data, index) => {
              return (
                <Table.Row key={data.id}>
                  <Table.Cell>{index + 1}</Table.Cell>
                  <Table.Cell>{data.name}</Table.Cell>
                  <Table.HeaderCell>{data.designation}</Table.HeaderCell>
                  <Table.Cell>{data.employeeId}</Table.Cell>
                  <Table.Cell>{data.phoneNumber}</Table.Cell>
                  <Table.Cell>{data.joiningDate}</Table.Cell>
                  <Table.HeaderCell> {data.address}</Table.HeaderCell>
                  {/* <Table.Cell>{data.salary}</Table.Cell> */}
                  <Table.Cell>
                    <Modal closeIcon open={open} trigger={
                      <Link to='/GetData'>
                        <Button color="green" onClick={() => setUpdateData({
                          id: data.id, name: data.name, designation: data.designation, phoneNumber: data.phoneNumber,
                          joiningDate: data.joiningDate, address: data.address, salary: data.salary, employeeId: data.employeeId
                        })}>Update User
                        </Button>
                      </Link>}
                      onClose={() => setOpen(false)}
                      onOpen={() => setOpen(true)}>
                      <Header icon='archive' content='Update Your Data' />

                      <Modal.Content>
                        <div>
                          <Form style={{ border: "2px solid black", padding: "12px", margin: "12px" }}>
                            <div>
                              <label htmlFor="name">Name:</label>
                              <input type="text"
                                value={update.name}
                                onChange={(e) => setUpdateData({ ...update, name: e.target.value })}
                                id="name"
                              ></input>
                            </div>
                            <div>
                              <label htmlFor="EmployeeId">EmployeeId:</label>
                              <input type="text"
                                value={update.employeeId}
                                onChange={(e) => setUpdateData({ ...update, employeeId: e.target.value })}
                                id="EmployeeId"
                              ></input>
                            </div>
                            <div>
                              <label htmlFor="designation">Designation:</label>
                              <input type="text"
                                value={update.designation}
                                onChange={(e) => setUpdateData({ ...update, designation: e.target.value })}
                                id="designation"
                              ></input>
                            </div>

                            <div>
                              <label htmlFor="phoneNumber">PhoneNumber:</label>
                              <input type="tel"
                                value={update.phoneNumber}
                                onChange={(e) => setUpdateData({ ...update, phoneNumber: e.target.value })}
                                id="phoneNumber"
                              ></input>
                            </div>
                            <div>
                              <label htmlFor="joiningDate">joiningDate:</label>
                              <input type="date"
                                value={update.joiningDate}
                                onChange={(e) => setUpdateData({ ...update, joiningDate: e.target.value })}
                                id="joiningDate"
                              ></input>
                            </div>

                            <div>
                              <label htmlFor="address">Address:</label>
                              <input type="text"
                                value={update.address}
                                onChange={(e) => setUpdateData({ ...update, address: e.target.value })}
                                id="address"
                              ></input>
                            </div>

                            <div>
                              <label htmlFor="salary">Salary:</label>
                              <input type="text"
                                value={update.salary}
                                onChange={(e) => setUpdateData({ ...update, salary: e.target.value })}
                                id="salary"
                              ></input>
                              <Button style={{ background: 'green', color: 'white', position: 'relative', left: '700px', top: '12px', bottom: '8px', margin: '15px' }} onClick={handleFormSubmit}>Save</Button>
                            </div>
                          </Form>
                        </div>


                      </Modal.Content>
                    </Modal>
                  </Table.Cell>
                  {/* <Table.Cell><Button color="red" onClick={()=>deleteData(data.id, false)}>Delete</Button></Table.Cell> */}
                  <Table.Cell>
                    <div className="ui  toggle checkbox ">
                      <input checked={data.status} onChange={() => deleteData(data.id, !data.status)} type="checkbox" /><label />
                    </div>
                  </Table.Cell>
                </Table.Row>
              )
            })}

        </Table.Body>
      </Table>




    </div>
  )

}

export default Read
