import { MdPerson, MdWork, MdLocalPhone, MdOutlineLocationCity, MdPassword, MdAttachMoney, MdDateRange } from 'react-icons/md';
import { BiHide, BiShow } from 'react-icons/bi'

export const Icons = {
    Name: MdPerson,
    Designation: MdWork,
    Phone: MdLocalPhone,
    City: MdOutlineLocationCity,
    Password: MdPassword,
    Salary: MdAttachMoney,
    Date: MdDateRange,
    Hide: BiHide,
    Show: BiShow
}
export const ERRORS = {
    NAME: 'Name is required.',
    designation: 'designation is required',
    MOBILE: '10 digit mobile number.',
    joiningDate: 'date is required',
    ADDRESS: 'Address is required.',
    PASSWORD: 'Password is required.',
    Salary: 'Salary is required.',

}