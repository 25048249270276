import { React, useState } from "react";
import '../components/clock.css';


// export let exSetCurrentDate, exSetCurrentTime;

const LocalClock = () => {
   // let time = new Date().toLocaleTimeString();
   // let fullHrs = new Date().toTimeString().split(' ')[0];
   //  console.log(time, fullHrs);

   const [currentDate, setCurrentDate] = useState();
   const [currentTime, setCurrentTime] = useState();


   const updateTime = () => {
      // let time = new Date().toLocaleTimeString();
      const currentTime = new Date();
      const nepTime = currentTime.toLocaleString('en-NP', { timeZone: 'Asia/Kathmandu' });

      //return `Current time ${nepTime}  GMT+0545 (Nepal Time)  `;
      const date = nepTime.split(',')[0];
      const time = nepTime.split(',')[1];
      const localTime = time.split(' ')[1];
      // exSetCurrentTime = date;
      // exSetCurrentDate = time;
      setCurrentDate(date);
      setCurrentTime(time);

      localStorage.setItem('date', date)
      localStorage.setItem('time', localTime)

      // console.log(((new Date()).toLocaleString('en-NP', { timeZone: 'Asia/Kathmandu' })).split(',')[1]).toString().split(' ')[1])
   }

   setInterval(updateTime, 1000);

   return (
      <div className="clock">
         <h5>Date:- {currentDate}<br /> Nepal Standard Time:- {currentTime}</h5>
      </div>
   )
}

export default LocalClock;


