import React from 'react'
import { useState } from 'react'
import { Table } from 'semantic-ui-react'
import { BaseUrl } from '../middleware/config';
import axios from 'axios';
import { useEffect } from 'react';
import './repo.scss';


const Report = () => {
    const [repoData, setRepoData] = useState([])
    const [isChecked, setChecked] = useState([])
    // const[fromDate, setFromDate] = useState();
    // const[toDate ,setToDate] = useState();
    const [reportData, setReportData] = useState({
        dateFrom: '',
        dateUpto: ''

    })
    useEffect(() => {
        getReportData();
    }, []);
    //  api to display table data
    const getReportData = async () => {
        await axios.get(`${BaseUrl}/employee/statusdata?status=working`)
            .then(getData => {
                setRepoData(getData.data);

            })
    }
    const handleCheckBox = (e) => {
        const { value, checked } = e.target;
        if (checked) {
            setChecked([...isChecked, value])
        }
        else {
            setChecked(isChecked.filter((e) => e !== value))
        }

    }
    console.log(isChecked, 'check')
    //
    const allSend = async (id) => {
        await axios.get(`${BaseUrl}/register/idDataByDate?id:`, { isChecked }).then(result => {
            console.log(result);
        });
    }
    return (
        <div>
            <div className="main_table">
                <div className="from_To_Date">
                    <input type="date" value={reportData.dateFrom} id="from_date" onChange={(e) => setReportData({ ...reportData, dateFrom: e.target.value })} />
                    <input type="date" value={reportData.dateUpto} id="to_date" onChange={(e) => setReportData({ ...reportData, dateUpto: e.target.value })} />
                </div>

                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>CheckBox</Table.HeaderCell>
                            <Table.HeaderCell>ID</Table.HeaderCell>
                            <Table.HeaderCell>Employee Id</Table.HeaderCell>
                            <Table.HeaderCell>Full Name</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {repoData.body && repoData.body.map((data, index) => {
                            return (
                                <Table.Row key={index}>
                                    <Table.Cell><input type="checkbox" value={data.employeeId} checked={data.isChecked} onChange={(e) => handleCheckBox(e)} /></Table.Cell>
                                    <Table.Cell>{data.id}</Table.Cell>
                                    <Table.Cell>{data.employeeId}</Table.Cell>
                                    <Table.Cell>{data.name}</Table.Cell>
                                </Table.Row>
                            )
                        })}
                    </Table.Body>
                </Table>
                <button className="get_btn" onClick={allSend}>Get Report</button>
            </div>
        </div>
    )
}

export default Report