import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Register from "./pages/Register";
import AddData from "./pages/AddData";
import GetData from "./pages/GetData";
import UpdatePass from "./pages/UpdatePass";
import "./components/Sidebar.css";
import Sidebar from "./components/Sidebar";
import { Header } from "semantic-ui-react";
import Report from "./pages/Report";
import Footer from './pages/Footer'
import LoginPage from './pages/login'

import "./header.css";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Sidebar>
          <Header className="headder">LIVE ATTENDANCE </Header>
          <Routes>
            <Route path="/" element={<Register />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/GetData" element={<GetData />} />
            <Route path="/updatePass" element={<UpdatePass />} />
            <Route path="/AddData" element={<AddData />} />
            <Route path="/report" element={<Report />} />
          </Routes>
          <Footer />
        </Sidebar>
      </BrowserRouter>
    </>
  );
};

export default App;
