import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { MdPerson, MdPassword } from "react-icons/md";
import { FaBars, FaThList } from "react-icons/fa";

const Sidebar = ({ children }) => {
    const [role, setRole] = useState(false);

    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const menuItem = [
        {
            path: "/",
            name: "Register",
            icon: < FaThList />


        },
        {
            path: "/GetData",
            name: "Employee",
            icon: <MdPerson />

        },

        {
            path: "/updatePass",
            name: "Update Pass",
            icon: <MdPassword />

        },
        {
            path: "/report",
            name: "Report",
            icon: <MdPerson />

        }
    ]

    const menuItemProduction = [
        {
            path: "/",
            name: "Register",
            icon: < FaThList />


        }
    ]

    useEffect(() => {
        const isDevelopment = process.env.NODE_ENV === 'development';
        console.log('Running in development:', isDevelopment);

        // Your logic based on the environment
        if (isDevelopment) setRole(true)
        else setRole(false)
    }, []);


    return (
        <div className="container">
            <div style={{ width: isOpen ? "200px" : "50px" }} className="sidebar">
                <div className="top_section">
                    <h5 style={{ display: isOpen ? "block" : "none" }} className="logo" onClick={toggle}>Live Register</h5>
                    <div style={{ display: isOpen ? "none" : "block", marginLeft: isOpen ? "50" : "0" }} className="bars">
                        <FaBars onClick={toggle} />
                    </div>
                </div>
                {
                    role === true ?
                        menuItem.map((item, index) => (
                            <NavLink to={item.path} key={index} className="link" activeclassname="active">
                                <div className="icon">{item.icon}</div>
                                <div style={{ display: isOpen ? "block" : "none" }} className="link_text">{item.name}</div>
                            </NavLink>
                        )) :
                        menuItemProduction.map((item, index) => (
                            <NavLink to={item.path} key={index} className="link" activeclassname="active">
                                <div className="icon">{item.icon}</div>
                                <div style={{ display: isOpen ? "block" : "none" }} className="link_text">{item.name}</div>
                            </NavLink>
                        ))
                }
            </div>
            <main>{children}</main>
        </div>
    );
};

export default Sidebar;
