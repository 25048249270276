import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');

    const navigate = useNavigate()

    const handleLogin = (e) => {
        e.preventDefault();
        // Implement login logic here
        if (userName === 'user' && password === 'lennobyte@2024#') {
            setMessage('Login successful!');
            localStorage.setItem('isLogin', JSON.stringify('OK'))
            navigate('/', { replace: true })
            // localStorage.setItem('user', JSON.stringify(user));
        } else {
            setMessage('Invalid userName or password');
            localStorage.removeItem('isLogin')
        }
    };

    useEffect(() => {
        let x = JSON.parse(localStorage.getItem('isLogin'));
        console.log('x', x)
        if (x === 'OK') {
            navigate('/', { replace: true });
        }

    }, [navigate])

    const containerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f5f5f5',
    };

    const formStyle = {
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#ffffff',
        textAlign: 'center',
        width: '300px',
    };

    const inputStyle = {
        marginBottom: '10px',
        padding: '10px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        width: '100%',
    };

    const buttonStyle = {
        padding: '10px',
        borderRadius: '5px',
        border: 'none',
        backgroundColor: '#007BFF',
        color: '#ffffff',
        cursor: 'pointer',
        width: '100%',
    };

    const messageStyle = {
        marginTop: '10px',
        color: message === 'Login successful!' ? 'green' : 'red',
    };

    return (
        <div style={containerStyle}>
            <form style={formStyle} onSubmit={handleLogin}>
                <h2>Lennobyte Solutions</h2>
                <input
                    type="text"
                    placeholder="User Name"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    style={inputStyle}
                    required
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    style={inputStyle}
                    required
                />
                <button type="submit" style={buttonStyle}>
                    Login
                </button>
                {message && <p style={messageStyle}>{message}</p>}
            </form>
        </div>
    );
};

export default LoginPage;
