import axios from "axios";
import { BaseUrl } from "../middleware/config";

const employeeData = async () => {
  let response = await axios.get(`${BaseUrl}/employee/getdata?isDropdown=1`)
  return response.data.body
}

const employeeList = (list) => {
  let lists = [];
  for (let i = 0; i < list.length; i++) {
    lists.push(<option key={i} value={list[i].value}>
      {list[i].text}-({(list[i].employeeId)})</option>)

  }
  return lists;
}


const roleData = async () => {
  let res = await axios.get(`${BaseUrl}/role/detail`)

  return res.data.body
}
console.log(roleData(), 'role')




const roleList = (list) => {
  let lists = [];
  for (let i = 0; i < list.length; i++) {
    lists.push(<option key={i} value={list[i].id}>{list[i].roles}</option>)
  }
  return lists
}



const attendance = [
  { text: 'Present', value: 1 },
  { text: 'Absent', value: 2 },
  { text: 'Paid Leave', value: 3 },
  { text: 'Unpaid Leave', value: 4 }
]

const attend = [];
for (let i = 0; i < attendance.length; i++) {
  attend.push(<option key={i} value={attendance[i].value}>{attendance[i].text}</option>)
}

export {
  employeeData, employeeList,
  roleData, roleList,
  attendance, attend
}